import { webClient } from "@api/web/WebClient";
import { useQueryParameter } from "@hooks/navigation";
import { WebTradeTermsGetResponse } from "@reshopper/web-client";
import Page from "@templates/Page";
import { useEffect, useState } from "react";

export default () => {
    const [versionId] = useQueryParameter("versionId");
    const [tradeTerms, setTradeTerms] = useState<WebTradeTermsGetResponse>();

    useEffect(() => {
        const fetchTradeTerms = async () => {
            const tradeTermsResponse = versionId ? await webClient().webTradeTermsGet(+versionId) : await webClient().webLatestTradeTermsGet();
            setTradeTerms(tradeTermsResponse);
        }
        fetchTradeTerms();
    }, [versionId]);

    return <Page pageContext={{
        html: tradeTerms?.terms,
        title: "Handelsbetingelser",
        description: "Handelsbetingelser",
        index: false,
        versionId: tradeTerms?.versionId,
        useReactMackdown: true
    }}/>
};